export default {
  siteTitle: 'Get bonus',
  blogerName: 'BRUWINBR',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/brunawinnbr'
    },
    {
      name: 'instagram',
      url: 'https://instagram.com/bruna_winbr?igshid=MjEwN2IyYWYwYw=='
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@brunawinbr9036'
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@bruwinbr'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1gocasino.life/cd97b3614',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/c09dcbc1b',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/cc0f4cddc',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c1fa5807f',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cdd16a2ff',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c5966a75c',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c8f140e28',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c112ffc73',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c64fbb5b2',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>BRUWINBR</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'BRUWINBR',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'brazil'
};
